import { useEffect, useState} from "react";

import {authHelper} from "../../../infrastructure/authHelper";
import {appConstants} from "../../../constants";
import NavBarLeft from "../../layout/dashboard/NavBarLeft";
import {useParams} from "react-router-dom";
import EditUserForm from "../../dashboard/users/EditUserForm";



export default function UserEdit(){

  const [userData, setUserData] = useState(null);

  const params = useParams();
  const userId = params.userId;

  const requestOptions = {
    method: 'GET',
    headers: authHelper.GetJsonAuthHeaders(),
  }

  useEffect(() => {
    console.log(1)
    fetch( appConstants.APIBaseUrl + '/users/' + userId, requestOptions )
      .then( response => {
        
        if( response.status === 401 ) {
          // todo - add redirect to login
          throw new Error('Not Authenticated')
        }

        if( response.status !== 200 ) {
          // todo add redirect and logout
          throw new Error('Not Authenticated')
        }
        return response.json()
      })
      .then( resp => {
        setUserData(resp)
      })
      .catch(e => {
        console.log(e)
      })
  }, []);

  
  return (
    <div className='dashboard'>
      <NavBarLeft />

      <div className="container">
        <h2>Edit User</h2>
        {
          userData !== null && 
          <EditUserForm userData={userData} redirectSuccess={'/dashboard/users'} method="PATCH"/>
        }
      </div>
    </div>
  )
}
