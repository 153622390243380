import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {appConstants} from "../../../constants";
import {authHelper} from "../../../infrastructure/authHelper";

const EditUserForm = ( props ) => {
  const [email, setEmail] = useState(props.userData && props.userData.email ? props.userData.email : '')
  const [username, setUsername] = useState(props.userData && props.userData.username ? props.userData.username : '')
  const [firstName, setFirstName] = useState(props.userData && props.userData.firstName ? props.userData.firstName : '')
  const [lastName, setLastName] = useState(props.userData && props.userData.lastName ? props.userData.lastName : '')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  
  const redirectSuccess =  props.redirectSuccess ?? '/login';
  
  let method = 'POST';
  let userId = ''; 
  if( props.userData && props.userData.id ) {
    method = 'PATCH';
    userId = props.userData.id;
  }

  const navigate = useNavigate();


  const emailValidation = ( email ) => {
    const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    if( email.length === 0 ) {
      return false;
    }

    return regex.test(email) === true;
  }

  const onsubmit = (e) => {
    e.preventDefault()

    if( ! emailValidation( email ) ) {
      alert('email is not valid');
      return;
    }
    
    const editUserWithEmptyPass = userId && password === '' && passwordConfirm === '';
    
    if( ! editUserWithEmptyPass ) {
      if( password.length < 3 ) {
        alert('Passwords is too short');
        return;
      }

      if( password !== passwordConfirm ) {
        alert('Passwords do not match');
        return;
      }
    }

    let body = {
      email, username, firstName, lastName
    }
    if( ! editUserWithEmptyPass ) {
      body.password = password
      body.passwordConfirm = passwordConfirm
    }


    const requestOptions = {
      method,
      headers: authHelper.GetJsonAuthHeaders(),
      body: JSON.stringify(body)
    };

    
    fetch(appConstants.APIBaseUrl + '/users/' + userId, requestOptions)
      .then(response => {
        if( response.status === 200 || response.status === 201 ) {
          navigate(redirectSuccess)
        }
        // todo - do I need to return response here?
      })
      .then(response => response.json())
      .then(data => {
        if( data.data ) {
          setPassword('')
          setPasswordConfirm('')
          alert(data.message);
        }
      }).catch( err => {
      console.log(err)
    })
  }



  return (
    <div className='form-wrapper form-wrapper__signup'>

      <form className='add-form' onSubmit={onsubmit}>
        <div className="form-control">
          <label>Username</label>
          <input type="text"
                 placeholder='Enter username'
                 value={username}
                 onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label>Email</label>
          <input type="text"
                 placeholder='Enter email'
                 value={email}
                 onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label>First Name</label>
          <input type="text"
                 placeholder='First Name'
                 value={firstName}
                 onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label>Last Name</label>
          <input type="text"
                 value={lastName}
                 onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label>Password</label>
          <input type="text"
                 value={password}
                 onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label>Confirm Password</label>
          <input type="text"
                 value={passwordConfirm}
                 onChange={(e) => setPasswordConfirm(e.target.value)}
          />
        </div>
        <div className="form-control">
          <input type="submit" value='Save' className='btn btn-block'/>
        </div>
      </form>
    </div>
  )
}

export default EditUserForm