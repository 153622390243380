import {NavLink} from "react-router-dom";

const NavBarLeft = (props ) => {
  return (
    <div className='dashboard-nav-bar'>
      <ul>
        <li>Users</li>
        <ul>
          <li><NavLink to="/dashboard/users" className={(navData) => (navData.isActive ? "app-link app-link__active" : 'app-link')}>All</NavLink></li>
          <li><NavLink to="/dashboard/users/new" className={(navData) => (navData.isActive ? "app-link app-link__active" : 'app-link')}>Add New</NavLink></li>
        </ul>
      </ul>
    </div>
  )
}

export default NavBarLeft