const HP = () => {

  return (
    <div className='homepage'>
      <div className="container">
        <h1>HP here</h1>
        <h3>some more text</h3>
        
        <p>some page content goes here </p>
      </div>
    </div>
  )
}

export default HP