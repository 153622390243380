import {useState} from 'react'
import {useNavigate} from "react-router-dom";
import {appConstants} from '../../constants'


const Login = (props) => {
  console.log('Login mounted')

  const navigate = useNavigate();
  
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const onsubmit = (e) => {
    e.preventDefault()

    if (!username) {
      alert('Please enter username');
      return;
    }
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password })
    };

    fetch(appConstants.APIBaseUrl + '/auth/login', requestOptions)
      .then(response => response.json())
      .then(data => {
        // todo - use response code? 
        if( data.accessToken === undefined ) {
          alert('Username of Password is incorrect');
        } else {
          // todo - maybe store expiration time
          localStorage.setItem('token', data.accessToken)
          props.onLogin(true)
          navigate('/', {replace: true}) 
        }
      }).catch(error => {
        console.log(error)
    });

  }

  return (
    <div className='form-wrapper form-wrapper__signup'>
      <form className='form' onSubmit={onsubmit}>

        <h1 className='form__header'>Login</h1>
        <div className="form-control">
          <label>Username</label>
          <input type="text"
                 placeholder='Enter Username'
                 value={username}
                 onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label>Password</label>
          <input type="password"
                 placeholder=''
                 onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="form-control">
          <input type="submit" value='Login' className='btn btn-block'/>
        </div>
      </form>
    </div>
  )
}

export default Login