import { useEffect, useState} from "react";

import NavBarLeft from "../../layout/dashboard/NavBarLeft";
import {useParams} from "react-router-dom";
import EditUserForm from "../../dashboard/users/EditUserForm";

export default function UserNew(){

  const [userData, setUserData] = useState(null);

  const params = useParams();
 
  console.log('add new fired')
  return (
    <div className='dashboard'>
      <NavBarLeft />

      <div className="container">
        <h1>New User</h1>
        <EditUserForm redirectSuccess={'/dashboard/users'}/>
      </div>
    </div>
  )
}
