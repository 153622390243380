import {Component} from "react";

import {authHelper} from "../../../infrastructure/authHelper";
import {appConstants} from "../../../constants";
import UsersTable from "../../dashboard/users/UsersTable";
import NavBarLeft from "../../layout/dashboard/NavBarLeft";


class Users extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isFetching:false,
      users: []
    }
  }

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers (){
    this.setState({...this.state, isFetching: true});


    const requestOptions = {
      method: 'GET',
      headers: authHelper.GetJsonAuthHeaders(),
    }
    fetch( appConstants.APIBaseUrl + '/users', requestOptions )
      .then( response => {
        
        if( response.status === 401 ) {
          authHelper.Logout()
          // todo - is there a way without force reload? 
          this.props.history.replace('/login');
          document.location.reload()
          
          throw new Error('Not Authenticated')
        }
        
        if( response.status !== 200 ) {
          this.setState({
            ...this.state, isFetching: false
          })
          throw new Error('Not Authenticated')
        }
        return response.json()
      })
      .then( resp => {
        if( resp[0].length ) {
          this.setState({users: resp[0], isFetching: true})
        }
      })
      .catch(e => {
        this.setState({
          ...this.state, isFetching: false
        })
      })
  }

  removeUser( id ) {
    console.log(id)
    const requestOptions = {
      method: 'DELETE',
      headers: authHelper.GetJsonAuthHeaders(),
    }
    fetch( appConstants.APIBaseUrl + '/users/' + id, requestOptions )
      .then( response => {
        if( response.status === 401 ) {
          authHelper.Logout()
          // todo - is there a way without force reload? 
          this.props.history.replace('/login');
          document.location.reload()
          throw new Error('Not Authenticated')
        }

        if( response.status !== 200 ) {
          throw new Error('Not Authenticated')
        }
        this.fetchUsers();
      })
  }

  
  
  render() {
    return (
      <div className='dashboard'>
        <NavBarLeft />
        <div className="container">
          <h1>Users List</h1>

          { ! this.state.isFetching &&
            <p className='text-center'>Loading ...</p>
          }

          
          { this.state.users !== null &&
            <UsersTable users={this.state.users} onRemoveUser={this.removeUser} />
          }
        </div>
      </div>
    )
  }
}

export default Users