import {authHelper} from "../../infrastructure/authHelper";
import {NavLink} from "react-router-dom";

const Header = ( props ) => {
  return (
    <header className='app-header'>
      <div className="container">
        <div className="header">
          {
            props.tokenExists &&
            <ul className='header-navigation'>
              <li>
                <NavLink to="/" className={(navData) => (navData.isActive ? "app-link app-link__active" : 'app-link')}>Home</NavLink>
              </li>
              <li>
                <NavLink to="/dashboard" className={(navData) => (navData.isActive ? "app-link app-link__active" : 'app-link')}>Dashboard</NavLink>
              </li>
            </ul>
          }
          


          <ul className='header-navigation header-navigation__right'>
            
            { ! props.tokenExists &&
              <li>
                <NavLink to="signup" className={(navData) => (navData.isActive ? "app-link app-link__active" : 'app-link')}>Signup</NavLink>
              </li>
            }

            { ! props.tokenExists &&
              <li>
                <NavLink to="login" className={(navData) => (navData.isActive ? "app-link app-link__active" : 'app-link')}>Login</NavLink>
              </li>
            }

            { props.tokenExists &&
              <li>
                <a className="app-link" href="/login" onClick={()=>{authHelper.Logout()}}>Logout</a>
              </li>
            }
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header
