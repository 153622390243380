import {Fragment} from "react";

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import Signup from "./componenets/pages/Signup";
import Login from "./componenets/pages/Login";
import HP from "./componenets/pages/HP";

import './App.css';
import Header from "./componenets/layout/Header";
import AuthWrapper from "./componenets/infrastructure/AuthHelper";
import {authHelper} from "./infrastructure/authHelper";
import {useState} from "react";
import Users from "./componenets/pages/dashboard/Users";
import NavBarLeft from "./componenets/layout/dashboard/NavBarLeft";
import { createBrowserHistory } from 'history';
import UserEdit from "./componenets/pages/dashboard/UserEdit";
import UserNew from "./componenets/pages/dashboard/UserNew";

function App() {

  const [tokenExists, setTokenExists] = useState(authHelper.TokenExists())
  const history = new createBrowserHistory();

  return (
    <Router history={history}>
      <Fragment>
        <Header tokenExists={tokenExists}/>

        <Routes>

          <Route element={<AuthWrapper/>}>
            <Route path="/" element={<HP />} />
          </Route>
          
          <Route exact path="/dashboard/" element={<AuthWrapper/> }>
            {/* todo use NavBarRight in one place */}
            <Route exact path='' element={<Fragment>
              <NavBarLeft/>
              <h2 className='text-center'>Dashboard main page</h2>
            </Fragment>} />
            <Route exact path="users" element={<Users history={history}/>} />
            <Route exact path="users/:userId" element={<UserEdit history={history}/>} />
            <Route exact path="users/new" element={<UserNew history={history}/>} />
          </Route>

          <Route path='/login' element={<Login onLogin={setTokenExists}/>}/>
          <Route path='/signup' element={<Signup/>}/>

        </Routes>
      </Fragment>
    </Router>
  );
}

export default App;
