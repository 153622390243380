import {appConstants} from "../constants";


export const authHelper = {
  TokenExists: () => {
    return authHelper.GetLocalStorageToken() !== null
  },
  GetLocalStorageToken: () => {
    return localStorage.getItem(appConstants.tokenKey)
  },
  GetJsonAuthHeaders: () => {
    return {
      'Content-Type' : 'application/json',
      'Authorization': 'Bearer ' + authHelper.GetLocalStorageToken()
    }
  },
  Logout: () => {
    localStorage.removeItem(appConstants.tokenKey)
  },
};