import {Navigate, Outlet} from "react-router-dom";
import {authHelper} from "../../infrastructure/authHelper"

const AuthHelper = () => {
  return ! authHelper.TokenExists()
    ? <Navigate to="/login" replace/>
    : <Outlet/>
  
}

export default AuthHelper