

const UsersTable = (props) => {
  return (
    <table className='table table__admin'>
      <thead>
        <tr>
          <th>ID</th>
          <th>Username</th>
          <th>Email</th>
          <th>Firstname</th>
          <th>Lastname</th>
        </tr>
      </thead>

      <tbody>
        {props.users.map((user, i) => {
          return (
            <tr key={i}>
              <td>#{user.id}</td>
              <td>
                {user.username}
                <div className='column-actions'>
                  {/*todo receive path automatically */}
                  <a className='link' href={'/dashboard/users/' + user.id}>Edit</a>
                  {/*todo automatically fires on page load */}
                  <a className='link link__remove' onClick={() => props.onRemoveUser(user.id)}>Remove</a>
                </div>
              </td>
              <td>{user.email}</td>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default UsersTable

